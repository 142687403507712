<template>
	<b-modal
		id="ModuleModal"
		:visible="modalVisibility(modalId)"
		:title="`${textCreateMode(createMode)} Module`"
		@show="onShow()"
		@hidden="resetModal()"
		@ok="onOk"
		hide-footer
		size="xl"
		ok-only
		no-close-on-backdrop
		ok-title="Done"
	>
		<module-form
			:create-mode="createMode"
			:selected-module="selectedModule"
			:modal-size="modalSize"
		></module-form>
	</b-modal>
</template>

<script>
import { SET_MODAL_STATE } from "@/core/services/store/modules/ui.module";

import ModuleForm from "@/view/pages/globaladmin/modules/forms/ModuleForm.vue";
export default {
	components: { ModuleForm },
	props: {
		createMode: { type: Boolean, default: false },
	},
	data() {
		return {
			selectedModule: null,
			modalSize: "xl",
			readOnly: true,
			modalId: "ModuleModal",
			isBusy: true,
			selected: "",
			selectedMaterialList: [],
			selectedQuizList: [],
		};
	},
	methods: {
		textCreateMode(mode) {
			if (mode) {
				return "Create";
			} else {
				return "Update";
			}
		},
		textApproveStatus(id) {
			let txt;
			switch (id) {
				case 1:
					txt = "Approved";
					break;
				case 2:
					txt = "Pending";
					break;
				case 3:
					txt = "Rejected";
					break;
			}
			return txt;
		},
		selectQuizRow(tableIndex) {
			// Rows are indexed from 0, so the third row is index 2
			this.$refs.selectableQuizTable.selectRow(tableIndex);
		},
		selectMaterialRow(tableIndex) {
			// Rows are indexed from 0, so the third row is index 2
			this.$refs.selectableMaterialTable.selectRow(tableIndex);
		},
		async onShow() {
			this.selectedModule = JSON.parse(
				JSON.stringify(this.$parent.selectedModule)
			);
		},
		onOk() {
			this.$store.dispatch(SET_MODAL_STATE, null);
		},
		resetModal() {
			this.$store.dispatch(SET_MODAL_STATE, null);
		},
	},
};
</script>
