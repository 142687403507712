<template>
	<div>
		<div>
			<b-row>
				<b-col cols="6">
					<b-row>
						<b-col>
							<base-input
								v-model="form.module_name"
								label="Name"
								:validator="$v.form.module_name"
								:messages="localMessages"
							></base-input>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<base-input
								v-model="form.category"
								label="Category"
								:validator="$v.form.category"
								:messages="localMessages"
							></base-input>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<base-input
								v-model="form.description"
								label="Description"
								:validator="$v.form.description"
								:messages="localMessages"
							></base-input>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<base-input
								type="textarea"
								v-model="form.long_description"
								label="Detail Description"
								:validator="$v.form.long_description"
								:messages="localMessages"
							></base-input>
						</b-col>
					</b-row>

					<b-row>
						<b-col>
							<label>Current Material(s)</label>
							<b-table
								:items="CurrentModuleItemsList"
								:fields="currentModuleItemFields"
								striped
								bordered
								show-empty
								hover
							></b-table>
						</b-col>
					</b-row>

					<b-row>
						<b-col>
							<b-row>
								<b-col>
									<label class="pt-3"
										>Selected Materials</label
									></b-col
								>
								<b-col cols="auto"
									><b-button
										variant="link"
										@click="onClearAllSelected"
										>Clear</b-button
									></b-col
								>
							</b-row>
							<div
								class="p-4 border-danger border text-danger"
								v-if="
									$v.form.trainee_module_item_list.$dirty &&
										$v.form.trainee_module_item_list
											.$invalid
								"
							>
								No module or quiz selected
							</div>
							<div
								class="p-4 bg-secondary"
								v-if="tempList.length === 0"
							>
								No module or quiz selected yet
							</div>
							<div>
								<draggable
									:list="tempList"
									class="list-group"
									ghost-class="ghost"
									:move="checkMove"
									@start="dragging = true"
									@end="dragging = false"
								>
									<div
										class="list-group-item"
										v-for="(item, index) in tempList"
										:key="`item-${index}`"
									>
										<template v-if="item.id"
											>QUIZ:
											{{ item.quiz_name }}</template
										>
										<template v-if="item.material_id"
											>MATERIAL:
											{{ item.material_name }}</template
										>
									</div>
								</draggable>
							</div>
						</b-col>
					</b-row>
					<b-row class="mt-6">
						<b-col class="d-flex flex-row-reverse">
							<b-button
								@click="onSubmit"
								class="ml-3"
								variant="primary"
								size="lg"
							>
								Submit
							</b-button>
							<b-button
								@click="onClearForm"
								class="ml-3"
								variant="secondary"
								size="lg"
							>
								Clear
							</b-button>
						</b-col>
					</b-row>
				</b-col>
				<b-col v-if="modalSize === 'xl'">
					<div>
						<h4>List of Material</h4>
						<p class="mb-5">
							Select or unselect the material you wanted to
							include or exclude in this module
						</p>

						<b-row class="mb-3">
							<b-col
								><div>
									<b-button
										@click="selectAllMaterialRows"
										class="mr-3"
										size="sm"
										>Select All</b-button
									>
									<b-button
										@click="clearSelectedMaterial"
										class="mr-3"
										size="sm"
										>Clear Selected</b-button
									>
								</div></b-col
							>
							<b-col>
								<template>
									<b-input-group size="sm">
										<b-form-input
											v-model="filterMaterial"
											type="search"
											placeholder="Type to Search"
										></b-form-input>

										<b-input-group-append>
											<b-button
												:disabled="!filterMaterial"
												@click="filterMaterial = ''"
												>Clear</b-button
											>
										</b-input-group-append>
									</b-input-group>
								</template>
							</b-col>
						</b-row>
						<b-table
							sticky-header="270px"
							ref="selectableMaterialTable"
							:filter="filterMaterial"
							striped
							selectable
							bordered
							empty-text="No quiz created. Please create the quiz first"
							show-empty
							hover
							:items="MaterialCollectionList"
							:fields="materialListFields"
							@row-selected="onMaterialRowSelected"
						></b-table>
					</div>
					<div>
						<h4>List of Quiz</h4>
						<p class="mb-5">
							Select or unselect the quiz you wanted to include or
							exclude in this module
						</p>

						<b-row class="mb-3">
							<b-col
								><div>
									<b-button
										@click="selectAllQuizRows"
										class="mr-3"
										size="sm"
										>Select All</b-button
									>
									<b-button
										@click="clearSelectedQuiz"
										class="mr-3"
										size="sm"
										>Clear Selected</b-button
									>
								</div></b-col
							>
							<b-col>
								<template>
									<b-input-group size="sm">
										<b-form-input
											v-model="filterQuiz"
											type="search"
											placeholder="Type to Search"
										></b-form-input>

										<b-input-group-append>
											<b-button
												:disabled="!filterQuiz"
												@click="filterQuiz = ''"
												>Clear</b-button
											>
										</b-input-group-append>
									</b-input-group>
								</template>
							</b-col>
						</b-row>
						<b-table
							ref="selectableQuizTable"
							:filter="filterQuiz"
							sticky-header="270px"
							striped
							selectable
							bordered
							empty-text="No quiz created. Please create the quiz first"
							show-empty
							hover
							:items="QuizCollectionList"
							:fields="quizListFields"
							@row-selected="onQuizRowSelected"
						></b-table>
					</div>
				</b-col>
			</b-row>

			<b-row class="mt-6">
				<b-col class="d-flex flex-row-reverse">
					<b-button
						variant="secondary"
						size="lg"
						@click="closeModal('QuestionAnswerModal')"
						>Cancel
					</b-button>
				</b-col>
			</b-row>
		</div>
	</div>
</template>

<script>
import {
	GET_ALL_QUIZ_LIST,
	GET_MATERIAL_LIST,
	GET_TRAINEE_MODULE_DATA,
} from "@/core/services/store/modules/globaladmin/trainingcourse.module";
import {
	CREATE_TRAINEE_MODULE,
	GET_ALL_TRAINEE_MODULE_LIST,
	UPDATE_TRAINEE_MODULE,
} from "@/core/services/store/modules/globaladmin/trainee.module";

import SystemHelper from "@/core/services/systemhelper.service";

import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import draggable from "vuedraggable";

export default {
	mixins: [validationMixin],
	components: { draggable },
	props: {
		createMode: { type: Boolean, default: false },
		selectedModule: { type: Object },
		modalSize: { type: String, default: "" },
	},
	data() {
		return {
			filterMaterial: "",
			filterQuiz: "",
			form: {
				module_name: "",
				category: "",
				description: "",
				long_description: "",
				trainee_module_item_list: null,
			},
			currentModuleItemFields: [{ key: "name" }, { key: "type" }],
			tempList: [],
			materialListFields: [
				{ key: "material_name" },
				{ key: "file_type" },
				{ key: "category" },
			],
			quizListFields: [
				{ key: "quiz_name" },
				{ key: "answer_type" },
				{ key: "category" },
			],
		};
	},
	validations: {
		form: {
			module_name: { required },
			category: { required },
			description: { required },
			long_description: { required },
			trainee_module_item_list: { required },
		},
	},
	computed: {
		CurrentModuleData() {
			if (
				Object.keys(
					this.$store.state.globaladmin_trainingcourse
						.traineeModuleData
				).length
			) {
				const module = this.$store.state.globaladmin_trainingcourse
					.traineeModuleData.posts;
				if (Array.isArray(module) && module.length) {
					return module[0];
				} else {
					return {};
				}
			} else {
				return {};
			}
		},
		CurrentModuleItemsList() {
			const module = this.CurrentModuleData;
			const quizList = this.QuizCollectionList;
			const materialList = this.MaterialCollectionList;

			if (
				Object.keys(module).length &&
				quizList.length &&
				materialList.length
			) {
				let items = [];
				let moduleItems = module.traineeModuleItems;
				for (let i = 0; i < moduleItems.length; i++) {
					let moduleItem = moduleItems.find((m) => {
						return m.order_no == i + 1;
					});
					if (moduleItem.material_id) {
						let materialData = materialList.find(
							(material) =>
								material.material_id == moduleItem.material_id
						);
						items.push({
							name: materialData.material_name,
							type: "Material",
						});
					} else if (moduleItem.quiz_id) {
						let quizData = quizList.find(
							(quiz) => quiz.id == moduleItem.quiz_id
						);
						items.push({
							name: quizData.quiz_name,
							type: "Quiz",
						});
					}
				}
				return items;
			} else {
				return [];
			}
		},
		QuizCollectionList() {
			const list = this.$store.state.globaladmin_trainingcourse
				.allQuizList;
			console.log(list);
			if (Array.isArray(list) && list.length) {
				return list;
			} else {
				return [];
			}
		},
		MaterialCollectionList() {
			const list = this.$store.state.globaladmin_trainingcourse
				.allMaterialList;
			console.log(list);
			if (Array.isArray(list) && list.length) {
				return list;
			} else {
				return [];
			}
		},
		processedTempList() {
			let newArr = [];
			this.tempList.forEach((item, index) => {
				if (item.material_id) {
					newArr.push({
						material_id: item.material_id,
						order_no: (index + 1).toString(),
						percentage: (100 / this.tempList.length).toFixed(2),
					});
				} else if (item.id) {
					newArr.push({
						quiz_id: item.id,
						order_no: (index + 1).toString(),
						percentage: (100 / this.tempList.length).toFixed(2),
					});
				} else {
					newArr = [];
				}
			});

			return newArr;
		},
	},
	methods: {
		checkMove: function(e) {
			window.console.log("Future index: " + e.draggedContext.futureIndex);
		},
		onClearAllSelected() {
			this.$refs.selectableQuizTable.clearSelected();
			this.$refs.selectableMaterialTable.clearSelected();
			this.tempList = [];
		},
		selectAllQuizRows() {
			this.$refs.selectableQuizTable.selectAllRows();
		},
		clearSelectedQuiz() {
			this.$refs.selectableQuizTable.clearSelected();
		},
		onQuizRowSelected(items) {
			// this.form.quiz_list = items;
			this.selectedQuizList = items;

			this.tempList = this.selectedQuizList.concat(
				this.selectedMaterialList
			);
		},
		selectAllMaterialRows() {
			this.$refs.selectableMaterialTable.selectAllRows();
		},
		clearSelectedMaterial() {
			this.$refs.selectableMaterialTable.clearSelected();
		},
		onMaterialRowSelected(items) {
			this.selectedMaterialList = items;
			this.tempList = this.selectedMaterialList.concat(
				this.selectedQuizList
			);
		},
		onClearForm() {
			this.$v.form.$reset();
			this.form = {
				module_name: "",
				category: "",
				description: "",
				long_description: "",
				trainee_module_item_list: [],
			};
			this.selectedQuizList = [];
			this.selectedMaterialList = [];
			this.tempList = [];
		},
		onSubmit() {
			this.form.trainee_module_item_list = JSON.stringify(
				this.processedTempList
			);
			this.$v.form.$touch();

			if (this.$v.form.$anyError) {
				return;
			}
			if (this.processedTempList.length < 1) {
				SystemHelper.basicSwalHandler(
					"Validation Error",
					"You have to add at least one material or quiz",
					"danger"
				);
				return;
			}

			let payload = this.form;

			if (!this.createMode) {
				SystemHelper.confirmationDialogHandler(
					{ title: "Confirmation", html: "Create Module?" },
					{ action: UPDATE_TRAINEE_MODULE, payload: payload },
					[
						{
							action: GET_ALL_TRAINEE_MODULE_LIST,
							payload: this.defaultPayload,
						},
					],
					this.$parent.modalId
				);
			} else {
				SystemHelper.confirmationDialogHandler(
					{ title: "Confirmation", html: "Create Module?" },
					{ action: CREATE_TRAINEE_MODULE, payload: payload },
					[
						{
							action: GET_ALL_TRAINEE_MODULE_LIST,
							payload: this.defaultPayload,
						},
					],
					this.$parent.modalId
				);
			}
		},
	},
	async mounted() {
		this.$store.dispatch(GET_MATERIAL_LIST, this.defaultPayload);
		this.$store.dispatch(GET_ALL_QUIZ_LIST, this.defaultPayload);

		if (!this.createMode) {
			this.form = this.selectedModule;
			console.log("form: ", this.form);

			// get module data
			const payload = {
				trainee_module_id: this.form.trainee_module_id,
			};
			await this.$store.dispatch(GET_TRAINEE_MODULE_DATA, payload);
		}
	},
};
</script>
